

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/admissibilite-aide-juridique-gouvernementale-gratuite-contributive.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost47 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Êtes-vous admissibles à l’aide juridique gouvernementale? - Soumissions Avocat"
        description="Découvrez à qui s'adresse l'aide juridique au Québec et à quoi elle sert. Obtenez un mandat d'aide juridique tout à fait gratuitement pour engager un avocat qui les accepte. Le droit criminel, le droit civil, le droit de la famille : ces domaines y sont tous admis. Trouvez un avocat compétent qui peut vous défendre avec l'aide juridique gouvernementale."
        image={LeadImage}>
        <h1>Êtes-vous admissibles à l’aide juridique gouvernementale?</h1>

					
					
						<p>Conscient des problèmes criants d’accès à la justice et du prix toujours élevé des services juridiques, le gouvernement du Québec met en place un programme d’aide juridique visant à venir en aide aux citoyens dont les moyens financiers sont limités. Ce service public est d’abord offert gratuitement aux gens percevant un salaire annuel en deçà du seuil minimal prévu par la loi pour ensuite être modifié en 1997 pour inclure un plus large éventail de gens dans le besoin avec l’ajout d’un volet contributif.</p>
<p><StaticImage alt="admissibilite-aide-juridique-gouvernementale-gratuite-contributive" src="../images/admissibilite-aide-juridique-gouvernementale-gratuite-contributive.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Étant également aux faits que peu de gens sont disposés à payer le plein prix pour les services d’un avocat, le gouvernement a étalement mis en place des critères d’admissibilité très spécifiques. Nous vous les présentons afin que vous puissiez <strong>vérifier votre propre admissibilité</strong> à ce programme d’aide juridique gouvernementale!</p>
<h2>Qu’est-ce que l’aide juridique et à qui s’adresse-t-elle?</h2>
<p>Tel que mentionné, l’aide juridique vise à élargir l’accès aux services d’un avocat pour les gens ayant de faibles revenus annuels. Les gens souhaitant bénéficier de ce programme doivent envoyer une demande en bonne et due forme au Bureau d’aide juridique le plus près de leur domicile. Cette démarche ne vise pas à engager un avocat, mais plutôt à <strong>obtenir un mandat d’aide juridique</strong>.</p>
<p>Ce document indiquera votre admissibilité soit gratuite ou contributive, ce qui vous permettra de présenter votre statut aux avocats d’un centre d’aide juridique ou à un avocat en pratique privé qui accepte les mandats d’aide juridique. Notez cependant que ce ne sont pas tous les avocats en pratique privée qui acceptent les mandats d’aide juridique.</p>
<p>De plus, les prestataires de l’aide sociale sont présumés éligibles à l’aide juridique gouvernementale. Ils peuvent dont recevoir les services d’un avocat gratuitement en envoyant simplement une demande conventionnelle. Pour les gens ne bénéficiant pas d’une telle assistance, il faudra divulguer les revenus annuels, la valeur des biens et des liquidités pour connaître leur admissibilité.</p>
<h2>Quels sont les services juridiques admissibles?</h2>
<p>Ce ne sont pas tous les recours qui sont couverts par l’aide juridique, autant auprès d’un centre communautaire qu’en pratique privée. Comme la demande comporte elle-même des formalités importantes telles que la preuve de revenu et de valeur d’actifs, assurez-vous que votre recours est couvert avant de vérifier votre éligibilité.</p>
<p><strong>Droit criminel : </strong>Que vous soyez accusé d’un acte criminel ou d’une simple infraction sommaire, l’aide juridique couvre les accusations criminelles et pénales de diverses natures.</p>
<p>Droit de la famille : Tous les recours englobant le droit de garde, le versement d’une pension alimentaire, le divorce et autre domaine de droit familial sont couverts par l’aide juridique.</p>
<p>Droit de l’immigration : L’aide juridique en matière d’immigration s’applique surtout aux menaces d’expulsion et de parrainage.</p>
<p>Droit civil et responsabilité civile : Les poursuites en dommages, les saisies, les droits du consommateur et les litiges d’ordre civils sont admissibles à une aide juridique gouvernementale.</p>
<p>Droit de la jeunesse : Les demandes impliquant l’intervention du Directeur de la protection de la jeunesse, l’adoption et les poursuites criminelles contre un mineur sont prises en charge par l’aide juridique.</p>
<p>Certains domaines <strong>sont interdits et inadmissibles</strong> à l’aide juridique gouvernementale. Notamment, les demandes jugées frivoles telles que des contestations d’élection. Les causes souvent reçues, mais refusées sous justification d’interdiction sont les poursuites en <strong>diffamation </strong>et en contestation de<strong> contravention de stationnement.</strong></p>
<h2>Seuils du volet gratuit</h2>
<p>Tel que stipulé, l’aide juridique comprend deux volets, soit celui gratuit et celui que l’on qualifie de contributif. Ce premier est un recours presque clé en main en ce sens que vous n’avez rien à défrayer; l’aide juridique paye l’avocat à votre place. Comment savoir si vous pouvez recevoir une aide complètement gratuite? En consultant les barèmes d’éligibilité. Ç</p>
<p><StaticImage alt="calcul-admissibilite-aide-juridique-gratuite-quebec-baremes" src="../images/calcul-admissibilite-aide-juridique-gratuite-quebec-baremes.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Le tableau ci-dessous compare votre situation familiale avec votre revenu annuel brut. Pour être admissibles, vous devez avoir un revenu qui est <strong>inférieur au montant indiqué</strong> dans la colonne de droite selon votre situation familiale. Dépasser ce montant n’implique pas votre disqualification complète; vous pourriez quand même jouir de l’aide contributive.</p>
<table>
<tbody>
<tr>
<td><strong>Situation familiale/civile</strong><strong> </strong></td>
<td><strong>Revenu annuel brut</strong></td>
</tr>
<tr>
<td><strong><strong>Personne seule</strong></strong></td>
<td>22 750 $</td>
</tr>
<tr>
<td><strong><strong>Adulte + 1 enfant</strong></strong></td>
<td>27 834 $</td>
</tr>
<tr>
<td><strong><strong>Adulte + 2 enfants et plus</strong></strong></td>
<td>29 714 $</td>
</tr>
<tr>
<td><strong><strong>Adulte + conjoint</strong></strong></td>
<td>31 661$</td>
</tr>
<tr>
<td><strong><strong>Adulte avec conjoint + 1 enfant</strong></strong></td>
<td>35 424 $</td>
</tr>
<tr>
<td><strong><strong>Adulte avec conjoint + 2 enfants</strong></strong></td>
<td>37 306 $</td>
</tr>
</tbody>
</table>
<h2>Seuils du volet contributif</h2>
<p>Lorsque votre situation financière précaire ne sera pas jugée suffisamment grave pour justifier une aide complètement gratuite, vous pourrez tout de même vous retourner vers le volet contributif de l’aide juridique. Celui prévoit, à l’instar du volet précédent, que la situation familiale sera comparée aux revenus annuels bruts. Cependant, le volet contributif prévoit des tranches d’éligibilité à l’intérieur desquelles une <strong>contribution entre 100$ et 800$</strong> sera attribuée. Plus les revenus sont faibles à l’intérieur d’une tranche, plus il est probable que la contribution attribuée se rapproche du 800$ et vice-versa.</p>
<table>
<tbody>
<tr>
<td><strong>Situation familiale/civile</strong><strong> </strong></td>
<td><strong>Revenu annuel brut</strong></td>
</tr>
<tr>
<td><strong><strong>Personne seule</strong></strong></td>
<td>22 751 $ à 31 778 $</td>
</tr>
<tr>
<td><strong><strong>Adulte + 1 enfant</strong></strong></td>
<td>27 835 $ à 38 872 $</td>
</tr>
<tr>
<td><strong><strong>Adulte + 2 enfants et plus</strong></strong></td>
<td>29 835 $ à 41 498 $</td>
</tr>
<tr>
<td><strong><strong>Adulte + conjoint</strong></strong></td>
<td>31 662 $ à 44 224 $</td>
</tr>
<tr>
<td><strong><strong>Adulte avec conjoint + 1 enfant</strong></strong></td>
<td>35 425 $ à 49 478 $</td>
</tr>
<tr>
<td><strong><strong>Adulte avec conjoint + 2 enfants</strong></strong></td>
<td>37 307 $ à 52 106 $</td>
</tr>
</tbody>
</table>
<h2>Barèmes des actifs et des liquidités</h2>
<p>Le revenu n’est pas le seul facteur financier pris en compte pour établir l’éligibilité d’une personne. La valeur des biens possédés et le montant de liquidités disponibles auront également une influence sur <strong>l’admissibilité au volet gratuit</strong> du programme d’aide juridique. Les requérants ne doivent dépasser aucun des barèmes prévus pour recevoir une aide gratuite.</p>
<p><strong>BIENS</strong></p>
<table>
<tbody>
<tr>
<td><strong>Requérant + conjoint propriétaire</strong></td>
<td><strong> 90 000$</strong></td>
</tr>
<tr>
<td><strong>Requérant + conjoint non propriétaire</strong></td>
<td><strong>47 500$</strong></td>
</tr>
</tbody>
</table>
<p><strong> </strong></p>
<p><strong>LIQUIDITÉS</strong></p>
<table>
<tbody>
<tr>
<td><strong>Personne seule</strong></td>
<td><strong>2500$</strong></td>
</tr>
<tr>
<td><strong>Famille</strong></td>
<td><strong>5000$</strong></td>
</tr>
</tbody>
</table>
<p><strong> </strong></p>
<p>Excéder un des barèmes pourrait quand même respecter les critères de l’aide contributive. Le fardeau de prouver l’admissibilité à l’aide juridique, gratuite ou contributive, repose toujours sur les épaules du requérant.</p>
<h2>Comment faire la demande et à qui?</h2>
<p>Que vous souhaitiez faire affaire avec un avocat privé acceptant les mandats d’aide juridique ou directement avec un Centre d’aide juridique, vous devez passer par l’étape initiale de présenter une demande à ce dernier endroit afin de confirmer votre admissibilité.</p>
<p>En vous rendant sur <strong>le site de la Commission des services juridiques</strong>, vous trouverez le bureau le plus près de chez vous ainsi que l’adresse à laquelle faire parvenir votre demande. Cette dernière devrait comprendre plusieurs documents permettant de déterminer l’importance de vos revenus. Parmi eux, on compte notamment :</p>
<ul>
<li>Une estimation des revenus annuels bruts</li>
<li>Numéro d’assurance sociale</li>
<li>Preuve d’inscription scolaire pour les mineurs</li>
<li>La source des revenus (Talons de paie, prestations, pourboires, revenus additionnels, etc.)</li>
<li>Montant de la pension alimentaire (si applicable)</li>
<li>Liste et valeur totale des actifs</li>
<li><strong>Les documents légaux déjà entamés.</strong></li>
</ul>
<p>Ce dernier point réfère aux documents légaux en lien avec votre cause. Si une mise en demeure a déjà été envoyée, si un contrat est au cœur du litige, si une assignation ou une citation à comparaître a été envoyée ou reçue de part et d’autre, vous devez envoyer une copie de ces documents conjointement à votre demande d’aide juridique.</p>
<h2>Les mineurs sont-ils admissibles à l’aide juridique?</h2>
<p><strong>Oui. </strong>Les mineurs sont bel et bien éligibles à l’aide juridique gouvernemental au même titre que les personnes majeures. La nuance se trouve au niveau des causes prises en charge et du calcul des liquidités et des biens déterminant son seuil d’admissibilité</p>
<p><StaticImage alt="aide-juridique-mineur-refusee-contributif-explication-quebec" src="../images/aide-juridique-mineur-refusee-contributif-explication-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Pour quelles causes un mineur peut-il présenter une demande d’aide juridique?</strong></p>
<ul>
<li><strong>Une dispute familiale</strong></li>
<li><strong>Une demande de modification de tutelle</strong></li>
<li><strong>Un recours pénal contre un adolescent</strong></li>
<li><strong>Représentation du mineur pour un cas impliquant le Directeur de la protection de la jeunesse.</strong></li>
</ul>
<p>Lorsque le mineur entreprend <strong>un recours avec l’appui de ses parents</strong>, on calculera la valeur des biens et liquidités du mineur, en plus de celle des biens et liquidités de ses parents.</p>
<p>Toutefois, lorsque le <strong>recours du mineur sera contre ses</strong> parents ou à l’encontre des intérêts de ceux-ci, on calculera les biens du mineur seulement pour établir son éligibilité.</p>
<h2>Aide juridique refusée?</h2>
<p>Le refus d’aide juridique ne réfère pas à votre non-admissibilité pour des raisons financières, mais bien au rejet d’une demande autrement éligible. Cela survient lorsqu’une demande qui respecte les critères financiers et légaux est rejetée en raison de la violation d’une obligation par le requérant de l’aide juriduqe.</p>
<p>Ainsi, si le requérant ment dans sa demande, omet d’informer l’aide juridique de développements dans son dossier ou dans sa vie personnelle qui ont une incidence sur le dossier, collabore inadéquatement ou encore refuse de verser la contribution indiquée, la demande pourra être refusée, même si elle avait été acceptée initialement.</p>
<h2>Soumissions Avocat peut vous mettre en contact avec un avocat acceptant les mandats d’aide juridique!</h2>
<p>À la lecture de ces lignes, vous vous croyez éligible à une aide juridique gouvernementale? Vous souhaitez engager un avocat pour régler votre litige et retrouver votre paix d’esprit? Vérifiez d’abord votre éligibilité auprès d’un centre d’aide juridique et, après avoir obtenu votre mandat, contactez Soumissions Avocat pour être mis en contact avec les meilleurs avocats dans votre région!</p>
<p><strong>Notre réseau de partenaires s’étend sur une grande partie de la province, alors nous serons en mesure de vous trouver un avocat acceptant les mandats d’aide juridique dans votre région.</strong></p>
<p><strong>Prêts à faire le grand saut? Remplissez le formulaire et votre demande sera acheminée à 3 avocats sans tarder! Nos services sont gratuits et sans engagement, alors qu’attendez-vous!</strong></p>
    </SeoPage>
)
export default BlogPost47
  